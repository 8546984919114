<template>
    <div class="box form-box has-background-white">
        <div class="enso-form">
            <enso-form ref="form"
                       @ready="init"
                       @submit="submit"
                       disable-state>
                <template v-slot:video_src="props">
                    <div class="columns custom-section-wrapper is-multiline">
                        <div class="column is-6 section-description">
                            <div class="field">
                                <div class="label">
                                    {{ i18n(props.field.label) }}
                                </div>
                            </div>
                            <b-field>
                                <b-upload v-model="new_video_src" drag-drop expanded accept="video/*" @input="setVideo">
                                    <section class="section">
                                        <div class="content has-text-centered">
                                            <p>
                                                <b-icon icon="upload" size="is-large"/>
                                            </p>
                                            <p>Click to upload</p>
                                        </div>
                                    </section>
                                </b-upload>
                            </b-field>
                        </div>
                        <div class="column is-6">
                            <b-tag v-if="video_src || new_video_src"
                                   closable
                                   aria-close-label="Remove"
                                   @close="resetVideo"
                                   class="remove_tag">
                                Remove
                            </b-tag>
                            <video v-if="new_video_src"
                                   :src="$displayImage(new_video_src)" width="100%" controls/>
                            <video v-if="video_src && !new_video_src"
                                   :src="video_src" width="100%" controls/>
                        </div>
                    </div>
                </template>
                <template v-slot:audio_src="props">
                    <div class="columns custom-section-wrapper is-multiline">
                        <div class="column is-6 section-description">
                            <div class="field">
                                <div class="label">
                                    {{ i18n(props.field.label) }}
                                </div>
                            </div>
                            <b-field>
                                <b-upload v-model="new_audio_src" drag-drop expanded accept="video/*" @input="setAudio">
                                    <section class="section">
                                        <div class="content has-text-centered">
                                            <p>
                                                <b-icon icon="upload" size="is-large"/>
                                            </p>
                                            <p>Click to upload</p>
                                        </div>
                                    </section>
                                </b-upload>
                            </b-field>
                        </div>
                        <div class="column is-6">
                            <b-tag v-if="audio_src || new_audio_src"
                                   closable aria-close-label="Remove" @close="resetAudio" class="remove_tag">
                                Remove
                            </b-tag>
                            <video v-if="new_audio_src"
                                   :src="$displayImage(new_audio_src)" width="100%" controls/>
                            <video v-if="audio_src && !new_audio_src"
                                   :src="audio_src" width="100%" controls/>
                        </div>
                    </div>
                </template>
                <template v-slot:logo="props">
                    <div class="columns custom-section-wrapper is-multiline">
                        <div class="column is-6 section-description">
                            <div class="field">
                                <div class="label">
                                    {{ i18n(props.field.label) }}
                                </div>
                            </div>
                            <b-field>
                                <b-upload v-model="new_logo" drag-drop expanded accept="image/*" @input="setLogo">
                                    <section class="section">
                                        <div class="content has-text-centered">
                                            <p>
                                                <b-icon icon="upload" size="is-large"/>
                                            </p>
                                            <p>Click to upload</p>
                                        </div>
                                    </section>
                                </b-upload>
                            </b-field>
                        </div>
                        <div class="column is-6">
                            <b-tag v-if="logo || new_logo"
                                   closable aria-close-label="Remove" @close="resetLogo" class="remove_tag">
                                Remove
                            </b-tag>
                            <img v-if="new_logo"
                                 :src="$displayImage(new_logo)">
                            <img v-if="logo && !new_logo"
                                 :src="'/storage/' + logo">
                        </div>
                    </div>
                </template>
            </enso-form>
        </div>
    </div>
</template>

<script>
import { EnsoForm } from '@enso-ui/forms/bulma';

export default {
    name: 'Edit',
    components: { EnsoForm },
    inject: ['i18n', 'route', 'toastr', 'errorHandler'],
    data: () => ({
        ready: false,
        video_src: null,
        new_video_src: null,
        audio_src: null,
        new_audio_src: null,
        logo: null,
        new_logo: null,
        remove_video_src: null,
        remove_audio_src: null,
        remove_logo: null,
    }),
    computed: {
        dirty() {
            return this.ready
                ? this.form.dirty
                : {};
        },
        form() {
            return this.ready
                ? this.$refs.form.$refs.form
                : {};
        },
    },
    methods: {
        init() {
            this.ready = true;
            this.form.field('location_id').value = this.$route.params.location;
            if (this.form.field('video_src').value) {
                // eslint-disable-next-line max-len
                this.video_src = `${window.location.protocol}//${window.location.host}/storage/${this.form.field('video_src').value}`;
            }
            if (this.form.field('audio_src').value) {
                // eslint-disable-next-line max-len
                this.audio_src = `${window.location.protocol}//${window.location.host}/storage/${this.form.field('audio_src').value}`;
            }
            if (this.form.field('logo').value) {
                this.logo = this.form.field('logo').value;
            }
        },
        prepareFormData() {
            const formData = new FormData();
            if (this.new_video_src) {
                formData.append('video_src', this.new_video_src);
            }
            if (this.new_audio_src) {
                formData.append('audio_src', this.new_audio_src);
            }
            if (this.new_logo) {
                formData.append('logo', this.new_logo);
            }
            if (this.remove_video_src) {
                formData.append('remove_video_src', 1);
            }
            if (this.remove_audio_src) {
                formData.append('remove_audio_src', 1);
            }
            if (this.remove_logo) {
                formData.append('remove_logo', 1);
            }
            formData.append('_method', 'PATCH');
            return formData;
        },
        submit(val) {
            const formData = this.prepareFormData();
            axios.post(
                this.route('locations.medias.media.update', val.media.id),
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                },
            ).catch(this.errorHandler);
        },
        setVideo() {
            this.form.field('video_src').value = this.new_video_src;
            this.video_src = null;
            this.remove_video_src = false;
        },
        setAudio() {
            this.form.field('audio_src').value = this.new_audio_src;
            this.audio_src = null;
            this.remove_audio_src = false;
        },
        setLogo() {
            this.form.field('logo').value = this.new_logo;
            this.logo = null;
            this.remove_logo = false;
        },
        resetVideo() {
            this.new_video_src = null;
            this.video_src = null;
            this.remove_video_src = true;
        },
        resetAudio() {
            this.new_audio_src = null;
            this.audio_src = null;
            this.remove_audio_src = true;
        },
        resetLogo() {
            this.new_logo = null;
            this.logo = null;
            this.remove_logo = true;
        },
    },
};
</script>
